import React from 'react';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Layout, SEO, ContentSection } from '@egonoid/gatsby-theme-common';

import styles from './blog.module.scss';

interface IProps {
  data: any;
  location: any;
}

const Blog: React.FC<IProps> = ({ data, location }) => {
  return (
    <Layout location={location} title={'Posts'} showLogo={true}>
      <SEO title="Posts" />
      <ContentSection>
        <h1>Welcome to our Blog!</h1>
      </ContentSection>
      <ContentSection>
        <div className={`${styles['blog__posts--container']}`}>
          {data.allMdxBlogPost.edges.map(({ node: post }) => {
            const images = {
              blogPostImages: [
                post.smBlogPostImage.childImageSharp.fluid,
                {
                  ...post.lgBlogPostImage.childImageSharp.fluid,
                  media: `(min-width: 1024px)`,
                },
                {
                  ...post.mdBlogPostImage.childImageSharp.fluid,
                  media: `(min-width: 639px) and (max-width: 1023px)`,
                },
              ],
            };

            return (
              <div key={post.id} className={`${styles['blog__post--container']}`}>
                <div>
                  {images && images.blogPostImages.length > 0 && (
                    <Image
                      fluid={images.blogPostImages}
                      alt={post.imageAlt ? post.imageAlt : post.excerpt}
                      className={styles.blog__image}
                    />
                  )}
                </div>
                <h2 className={`${styles['blog__post--headline']}`}>
                  <Link to={post.slug}>{post.title}</Link>
                </h2>
                <p>{post.excerpt}</p>
              </div>
            );
          })}
        </div>
      </ContentSection>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdxBlogPost(filter: { isVisible: { eq: true } }, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          date
          template
          status
          smBlogPostImage: image {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 220, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mdBlogPostImage: image {
            childImageSharp {
              fluid(maxWidth: 480, maxHeight: 320, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          lgBlogPostImage: image {
            childImageSharp {
              fluid(maxWidth: 370, maxHeight: 240, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          excerpt
          slug
        }
      }
    }
  }
`;

export default Blog;
